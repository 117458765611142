import {
	AdditionalUserColour,
	BaseUserColour,
	Capability,
	ClusterAnalysis,
	EngineId,
	ExtraUserColour,
	LikedItem,
} from './commonTypes';

// These constants are used by both backend and frontend

export const ENGINE_TYPES = {
	GAMES: {
		id: 'Games' as EngineId,
		name: 'Experiential',
		fileAllowed: false,
	},
	INNOVATIVE: {
		id: 'Innovative' as EngineId,
		name: 'Innovative',
		fileAllowed: false,
	},
	CREATIVE: {
		id: 'Creative' as EngineId,
		name: 'Creative',
		fileAllowed: false,
	},
	CREATE: {
		id: 'Create' as EngineId,
		name: 'Create',
		fileAllowed: true,
	},
};

export type UseCase =
	| 'branding'
	| 'brainstorming'
	| 'copywriting'
	| 'design_thinking'
	| 'product_idea'
	| 'product_brief'
	| 'marketing'
	| 'default';

export const TOOLS = {
	SPARK: 'spark',
	EMPATHISE: 'empathise',
	BRAINSTORM: 'brainstorm',
	COMMON_IDEAS: 'commonIdeas',
	TAGLINES: 'taglines',
	HAIKUS: 'haikus',
	Ai_HAIKUS: 'aiHaikus',
	PERSONAS: 'personas',
	MOTIVATIONS: 'motivations',
	GENERATE: 'generate',
	SYNTHESISE: 'synthesise',
	IMAGE: 'image',
};

// Full roles are in the database as capabilities of each role may be changed dynamically
export const ROLE_ID = {
	FREE: 'restricted',
	PARTICIPANT: 'participant',
	FACILITATOR: 'facilitator',
	TRIAL: 'freemium',
	INDIVIDUAL: 'freelancer',
	TEAM: 'paid',
	STUDENT: 'student',
	TEACHER: 'teacher',
};

export type RoleId = (typeof ROLE_ID)[keyof typeof ROLE_ID];

export const CAPABILITIES: Array<Capability> = [
	{
		key: 'create-board',
		name: 'Create board',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'board-limit',
		name: 'Board limit',
		implemented: true,
		type: 'number',
	},
	{
		key: 'create-project',
		name: 'Create project',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'time-limited',
		name: 'Time-limited',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'collaborate-one',
		name: 'Collaborate (up to 2 users)',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'collaborate-two',
		name: 'Collaborate (up to 3 users)',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'collaborate-group',
		name: 'Collaborate (up to 4 users)',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'collaborate-max',
		name: 'Collaborate (up to 8 users)',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'export',
		name: 'Export',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'slides',
		name: 'Slides',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'advanced-ai',
		name: 'Advanced AI tools',
		implemented: true,
		type: 'boolean',
	},
	{
		key: 'image-quota',
		name: 'Image quota (monthly)',
		implemented: true,
		type: 'number',
	},
];

export type AnalysisKey =
	| 'Psych.cluster'
	| 'Archetype.cluster'
	| 'Plots.cluster'
	| 'colors.cluster'
	| 'Themes.cluster'
	| 'Random0.cluster'
	| 'Greek.cluster'
	| 'Motivations.cluster'
	| 'Motivation-Challenge.cluster'
	| 'Motivation-Harmony.cluster'
	| 'Motivation-Stimulation.cluster'
	| 'Motivations-Novelty.cluster'
	| 'Motivation-Threat.cluster';
export const ANALYSES_TYPES: Record<AnalysisKey, ClusterAnalysis> = {
	'Psych.cluster': { name: 'Personality', generate: ['persona', 'audience'] },
	'Archetype.cluster': { name: 'Archetype', generate: ['persona', 'audience'] },
	'Plots.cluster': { name: 'Plots', generate: ['persona'] },
	'colors.cluster': { name: 'Visual', generate: ['persona'] },
	'Themes.cluster': { name: 'Themes', generate: ['persona', 'motivations'] },
	'Random0.cluster': { name: 'Random', generate: ['persona', 'motivations'] },
	'Greek.cluster': { name: 'Greek Gods', generate: ['persona', 'audience'] },
	'Motivations.cluster': { name: 'Motivations', generate: ['motivations'] },
	'Motivation-Challenge.cluster': {
		name: 'Challenge',
		category: 'Motivations',
		generate: ['motivations'],
	},
	'Motivation-Harmony.cluster': {
		name: 'Harmony',
		category: 'Motivations',
		generate: ['motivations'],
	},
	'Motivation-Stimulation.cluster': {
		name: 'Stimulation',
		category: 'Motivations',
		generate: ['motivations'],
	},
	'Motivations-Novelty.cluster': {
		name: 'Novelty',
		category: 'Motivations',
		generate: ['motivations'],
	},
	'Motivation-Threat.cluster': {
		name: 'Threat',
		category: 'Motivations',
		generate: ['motivations'],
	},
};

export const userColours = ['orange', 'fuchsia', 'red', 'green'] as Array<BaseUserColour>;
export const userColoursAdditional = [
	'dark',
	'violet',
	'yellow',
	'cyan',
] as Array<AdditionalUserColour>;

export const userColoursExtra = [
	'blue',
	'pink',
	'lime',
	'teal',
	'maroon',
	'turquoise',
	'purple',
	'navy',
	'salmon',
	'forestGreen',
	'olive',
	'chocolate',
] as Array<ExtraUserColour>;

export const IMAGE_TASK_IDS = [
	'Image-Photorealistic',
	'Image-Artistic',
	'Image-Oil',
	'Image-Line',
	'Image-Anime',
	'Image-Watercolors',
	'Image-Architecture',
	'Image-Portrait',
] as const;
export type ImageTaskId = (typeof IMAGE_TASK_IDS)[number];

export const SUB_TASK_IDS = [
	'PESTEL-P',
	'PESTEL-E',
	'PESTEL-S',
	'PESTEL-T',
	'PESTEL-Env',
	'PESTEL-L',
	'SWOT-S',
	'SWOT-W',
	'SWOT-O',
	'SWOT-T',
	'SCAMPER-S',
	'SCAMPER-C',
	'SCAMPER-A',
	'SCAMPER-M',
	'SCAMPER-P',
	'SCAMPER-E',
	'SCAMPER-R',
	...IMAGE_TASK_IDS,
] as const;
export type SubTaskId = (typeof SUB_TASK_IDS)[number];
type Task = {
	name: string;
	label?: string;
	prompt?: string;
	likeType?: LikedItem['type'];
	options?: Record<
		SubTaskId,
		{
			name: string;
			optionName: string;
			requiresAdvanced?: boolean;
			likeType?: LikedItem['type'];
		}
	>;
};

export const SYNTHESISE_AI_TASKS = {
	Summary: {
		name: 'Write a SUMMARY',
		likeType: 'summary',
	} as Task,
	Extract: {
		name: 'EXTRACT the essence',
		likeType: 'extract',
	} as Task,
	Unite: {
		name: 'Find a COMMON IDEA uniting several topics',
		likeType: 'unite',
	} as Task,
	Tagline: {
		name: 'Create 5 TAGLINEs',
		likeType: 'taglines',
		label: 'Taglines',
	} as Task,
	// add post for linkedin post.
	Post: {
		name: 'Create a Linkedin Post',
		likeType: 'linkedinpost',
		label: 'Post',
	} as Task,
	Strategy: {
		label: 'Brand Strategy',
		name: 'Propose STRATEGY for the brand',
		likeType: 'brand-strategy',
	} as Task,
	Synopsis: {
		label: 'Story Synopsis',
		name: 'Write a STORY SYNOPSIS (up to one page)',
		likeType: 'synopsis',
	} as Task,
	Brief: {
		label: 'Product Brief',
		name: 'Write PRODUCT BRIEF (one page long)',
		likeType: 'product-brief',
	} as Task,
	'Prod-ideas': {
		label: 'Product Idea',
		name: 'Come up with a PRODUCT IDEA',
		likeType: 'product-idea',
	} as Task,
	Audience: {
		label: 'Target Audiences',
		name: 'Propose 3-4 TARGET AUDIENCES',
		likeType: 'audience',
	} as Task,
	Game: {
		name: 'Create a GAME',
		likeType: 'game',
	} as Task,
	Brainstorming: {
		name: 'BRAINSTORM',
		likeType: 'brainstorm',
	} as Task,
	PESTEL: {
		name: 'Apply the PESTEL framework to analyze the impact of macro external factors',
		prompt: 'Which of the following external factors would you like to analyze now?',
		options: {
			'PESTEL-P': {
				name: 'Political perspective',
				optionName: 'P',
				likeType: 'pestel-p',
			},
			'PESTEL-E': {
				name: 'Economic impact',
				optionName: 'E',
				likeType: 'pestel-e',
			},
			'PESTEL-S': {
				name: 'Social factors',
				optionName: 'S',
				likeType: 'pestel-s',
			},
			'PESTEL-T': {
				name: 'Technological point of view',
				optionName: 'T',
				likeType: 'pestel-t',
			},
			'PESTEL-Env': {
				name: 'Environmental factors',
				optionName: 'Env',
				likeType: 'pestel-env',
			},
			'PESTEL-L': {
				name: 'Legal aspects',
				optionName: 'L',
				likeType: 'pestel-l',
			},
		},
	} as Task,
	SCAMPER: {
		name: 'Employ the SCAMPER technique within a creative framework',
		prompt: 'What aspect of improvement would you like to implement now?',
		options: {
			'SCAMPER-S': {
				name: 'Substitute',
				optionName: 'S',
				likeType: 'scamper-s',
			},
			'SCAMPER-C': {
				name: 'Combine',
				optionName: 'C',
				likeType: 'scamper-c',
			},
			'SCAMPER-A': {
				name: 'Adapt',
				optionName: 'A',
				likeType: 'scamper-a',
			},
			'SCAMPER-M': {
				name: 'Modify',
				optionName: 'M',
				likeType: 'scamper-m',
			},
			'SCAMPER-P': {
				name: 'Put to another use',
				optionName: 'P',
				likeType: 'scamper-p',
			},
			'SCAMPER-E': {
				name: 'Eliminate',
				optionName: 'E',
				likeType: 'scamper-e',
			},
			'SCAMPER-R': {
				name: 'Reverse',
				optionName: 'R',
				likeType: 'scamper-r',
			},
		},
	} as Task,
	SWOT: {
		name: 'SWOT analysis focusses on strategies for internal actions',
		prompt: 'What situational assessment aspect would you like to analyse now?',
		options: {
			'SWOT-S': {
				name: 'Strengths',
				optionName: 'S',
				likeType: 'swot-s',
			},
			'SWOT-W': {
				name: 'Weaknesses',
				optionName: 'W',
				likeType: 'swot-w',
			},
			'SWOT-O': {
				name: 'Opportunities',
				optionName: 'O',
				likeType: 'swot-o',
			},
			'SWOT-T': {
				name: 'Threats',
				optionName: 'T',
				likeType: 'swot-t',
			},
		},
	} as Task,
	Image: {
		name: 'Create an IMAGE',
		prompt: 'What kind of image would you like to create?',
		options: {
			'Image-Artistic': {
				name: 'Oil Paint, Line Art, Anime Cartoons, or Water colours.',
				optionName: 'Artistic (random style)',
			},
			'Image-Oil': {
				name: 'Oil Paint.',
				optionName: 'Artistic (Oil Paint)',
				requiresAdvanced: true,
			},
			'Image-Line': {
				name: 'Line Art.',
				optionName: 'Artistic (Line Art)',
				requiresAdvanced: true,
			},
			'Image-Anime': {
				name: 'Anime Cartoons',
				optionName: 'Artistic (Anime)',
				requiresAdvanced: true,
			},
			'Image-Watercolors': {
				name: 'Water colours / aquarelle.',
				optionName: 'Artistic (Water colours)',
				requiresAdvanced: true,
			},
			'Image-Photorealistic': {
				name: 'Looks like a photo.',
				optionName: 'Photorealistic',
				requiresAdvanced: true,
			},
			'Image-Architecture': {
				name: 'Architecture Illustration.',
				optionName: 'Photo: Architecture',
				requiresAdvanced: true,
			},
			'Image-Portrait': {
				name: 'Photorealistic Portrait.',
				optionName: 'Photo: Portrait',
				requiresAdvanced: true,
			},
		},
	} as Task,
};

export const EMPATHISE_COLOR_MAP: {
	[key: string]: string; // Flexible string indexing
} = {
	positive: 'GREEN',
	Positive: 'GREEN',
	negative: 'RED',
	Negative: 'RED',
	emotional: 'FUSCHIA',
	Emotional: 'FUSCHIA',
	cooccurrences: 'BLUE',
	Cooccurrences: 'BLUE',
	cooccurrence: 'BLUE',
	Cooccurrence: 'BLUE',
	'co-occurrences': 'BLUE',
	'Co-occurrences': 'BLUE',
	'co-occurrence': 'BLUE',
	'Co-occurrence': 'BLUE',
};

export const CLIENT_CONFIG = {
	BOSCH: {
		emailEndings: ['bosch.de', 'bosch.com', 'us.bosch.com'],
		topicVariable: 'BOSCH_TOPIC_ASSISTANT_ID',
		empathiseVariable: 'BOSCH_EMPATHISE_ASSISTANT_ID',
	},
	UNICEF: {
		emailEndings: ['unicef.org'],
		topicVariable: 'UNICEF_TOPIC_ASSISTANT_ID',
		empathiseVariable: 'UNICEF_EMPATHISE_ASSISTANT_ID',
	},
	// Add more clients as needed
};

export const PUBLIC_DOMAINS = [
	'gmail.com',
	'yahoo.com',
	'outlook.com',
	'hotmail.com',
	'live.com',
	'aol.com',
	'icloud.com',
	'mail.com',
	'gmx.com',
	'protonmail.com',
	'zoho.com',
	'yandex.com',
	'me.com',
	'msn.com',
	'fastmail.com',
	'hushmail.com',
	'rediffmail.com',
	'comcast.net',
	'sbcglobal.net',
	'verizon.net',
	'att.net',
	'btinternet.com',
	'earthlink.net',
	'qq.com',
	'sina.com',
	'126.com',
	'163.com',
	'lycos.com',
	'aim.com',
	'rambler.ru',
];
