import * as sharedb from 'sharedb/lib/client';
import { NCBoard, UserFieldName } from '../types';
import {
	NCGraphData,
	GraphMode,
	BoardUser,
	UserSelection,
	NCNode,
	NCLink,
	NCGroup,
	NCCluster,
	NCLinkMinimal,
} from '../../../src/commonTypes';
import { AnalysisKey } from '../../../src/commonConstants';
import { isSameLink, minimizeLinks } from './graphs/linkUtils';
import { minimizeGroups } from './graphs/groupUtils';
import { isSameCluster } from './graphs/clusterUtils';

export const extractGraphData = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	graphMode: GraphMode,
	isCollaborative: boolean
): NCGraphData => {
	let graphData: NCGraphData = { nodes: [], links: [] };
	if (isCollaborative && graphMode !== 'ideate') {
		const sharedGraphData: NCGraphData = doc.data[graphMode].data;
		if (sharedGraphData) {
			graphData = sharedGraphData;
		}
	} else {
		const personalGraphPath = graphMode === 'ideate' && isCollaborative ? 'ideateTeam' : graphMode;
		const personalGraphData: undefined | NCGraphData =
			doc.data[userFieldName]?.[personalGraphPath]?.data;
		if (personalGraphData) {
			graphData = personalGraphData;
		} else {
			if (graphMode === 'ideate' && isCollaborative && !graphData.analyses) {
				graphData.analyses = doc.data[userFieldName]?.ideate?.data.analyses;
				graphData.activeAnalysis = doc.data[userFieldName]?.ideate?.data.activeAnalysis;
			}
			doc.submitOp(
				[
					{
						p: [userFieldName, personalGraphPath],
						// Object data after:
						oi: {
							data: graphData,
						},
					},
				],
				{},
				shareDbCallback(`Initialised personal data for ${personalGraphPath}`)
			);
		}
	}
	graphData.uploadedFiles = doc.data.uploadedFiles;
	graphData.template = doc.data.template;
	return graphData;
};

const handleShareDBError = (err: sharedb.Error) => {
	if (err.message.startsWith('403: Permission denied')) {
		console.error('Permission denied, user logged out so return to dashboard / login');
		window.location.href = '/';
	} else {
		console.error(err);
	}
};

export const getInspireDataForIdeate = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean,
	colourClass: string,
	colourHex: string
) => {
	let inspireNodesToAnalyse = doc.data.inspire.data.nodes;
	let activeAnalysis: AnalysisKey | undefined;
	if (globalThis.neuroCreate.graph && globalThis.neuroCreate.graph.graphMode === 'ideate') {
		// When switching between personal/team, preserve the active analysis
		const userData = doc.data[userFieldName];
		const otherIdeateModeData = globalThis.neuroCreate.graph.isCollaborative
			? userData?.ideateTeam?.data
			: userData?.ideate?.data;
		if (otherIdeateModeData) {
			activeAnalysis = otherIdeateModeData.activeAnalysis;
		}
	}
	if (!isCollaborative) {
		// Only include nodes created by this user
		inspireNodesToAnalyse = inspireNodesToAnalyse.filter(
			(n) => n.colour === colourClass || n.colour === colourHex
		);
	}

	return {
		inspireNodesToAnalyse,
		activeAnalysis,
		vision: doc.data.vision,
		pdfSummary: doc.data.pdfSummary,
	};
};

export const makeSaveSingleUserFn = (doc: sharedb.Doc<NCBoard>) => {
	return (user: BoardUser, callback: (() => void) | null = null) => {
		if (doc.data) {
			// replace the user in the array
			const userIndex = doc.data.users.findIndex((u) => u.id === user.id);
			if (userIndex !== -1) {
				doc.submitOp(
					[
						{
							p: ['users', userIndex],
							// Object data before:
							ld: doc.data.users[userIndex],
							// Object data after:
							li: user,
						},
					],
					{},
					function (err) {
						if (err) {
							handleShareDBError(err);
						} else {
							callback && callback();
						}
					}
				);
			} else {
				console.error('User not found in users array when updating user');
			}
		}
	};
};

/** @deprecated */
export const makeSaveUsersFn = (doc: sharedb.Doc<NCBoard>) => {
	return (users: Array<BoardUser>, callback: (() => void) | null = null) => {
		if (doc.data) {
			doc.submitOp(
				[
					{
						p: ['users'],
						// Object data before:
						od: doc.data.users,
						// Object data after:
						oi: users,
					},
				],
				{},
				function (err) {
					if (err) {
						handleShareDBError(err);
					} else {
						callback && callback();
					}
				}
			);
		}
	};
};

export const makeSaveUserAnalyticsFn = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName
) => {
	return (userSelections: Array<UserSelection>, callback: (() => void) | null = null): void => {
		const path = [userFieldName, 'userSelections'];

		const dataBefore = doc.data[userFieldName]?.userSelections;

		// Submit the updated analytics data to ShareDB
		doc.submitOp(
			[
				{
					p: path,
					// Object data before:
					od: dataBefore,
					// Object data after:
					oi: userSelections,
				},
			],
			{},
			function (err) {
				if (err) {
					handleShareDBError(err);
				} else {
					console.log(`Saved user analytics data`);
					callback && callback();
				}
			}
		);
	};
};

const getGraphPathAndData = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean,
	graphMode: GraphMode
): [Array<string>, undefined | NCGraphData] => {
	// Choose path within the doc, based on graphMode (inspire/ideate/moodboard) and isCollaborative
	let path = isCollaborative ? [graphMode, 'data'] : [userFieldName, graphMode, 'data'];

	// Find previous data at this path as required by ShareDB
	let dataBefore: undefined | NCGraphData = isCollaborative
		? doc.data[graphMode].data
		: doc.data[userFieldName]?.[graphMode]?.data;

	// Special case for ideate, isCollaborative
	if (graphMode === 'ideate' && isCollaborative) {
		path = [userFieldName, 'ideateTeam', 'data'];
		dataBefore = doc.data[userFieldName]?.ideateTeam?.data;
	}
	// console.log('path', path);

	return [path, dataBefore];
};

const shareDbCallback = (successMessage: string) => {
	return (err: sharedb.Error) => {
		if (err) {
			handleShareDBError(err);
		} else {
			console.log(successMessage);
		}
	};
};

export const makeSaveGraphFn = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean
) => {
	return (
		graphMode: GraphMode,
		graphData: NCGraphData,
		callback: (() => void) | null = null
	): void => {
		const [path, dataBefore] = getGraphPathAndData(doc, userFieldName, isCollaborative, graphMode);

		if (callback) {
			globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
		}
		// Submit the updated graph data to ShareDB
		doc.submitOp(
			[
				{
					p: path,
					// Object data before:
					od: dataBefore,
					// Object data after:
					oi: graphData,
				},
			],
			{},
			shareDbCallback(
				`Saved graph data for ${graphMode} (${userFieldName}, ${
					isCollaborative ? 'collaborative' : 'personal'
				})`
			)
		);
	};
};

export const makeSaveNodesFunctions = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean,
	graphMode: GraphMode
) => {
	return {
		create: (nodes: NCNode[], callback: (() => void) | null = null): void => {
			if (nodes.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't save nodes without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			doc.submitOp(
				nodes.map((node, i) => ({
					p: [...path, 'nodes', dataBefore.nodes.length + i],
					li: node,
				})),
				{},
				shareDbCallback(
					`Created ${nodes.length} nodes (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		update: (nodes: NCNode[], callback: (() => void) | null = null): void => {
			if (nodes.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't update nodes without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			doc.submitOp(
				nodes.map((node) => ({
					p: [...path, 'nodes', dataBefore.nodes.findIndex((n) => n.uid === node.uid)],
					li: node,
					ld: dataBefore.nodes.find((n) => n.uid === node.uid),
				})),
				{},
				shareDbCallback(
					`Updated ${nodes.length} nodes for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		updateIfMoved: (
			nodes: NCNode[],
			oldNodes: NCNode[],
			callback: (() => void) | null = null
		): void => {
			if (nodes.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't update nodes without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const nodesToUpdate = nodes.filter((node) => {
				const oldNode = oldNodes.find((n) => n.uid === node.uid);
				if (!oldNode) {
					return true;
				}
				const diffX = Math.abs(oldNode.x - node.x);
				const diffY = Math.abs(oldNode.y - node.y);
				const moveDistance = Math.hypot(diffX, diffY);

				return moveDistance > 5;
			});
			if (nodesToUpdate.length === 0) {
				callback && callback();
				return;
			}

			doc.submitOp(
				nodesToUpdate.map((node) => ({
					p: [...path, 'nodes', dataBefore.nodes.findIndex((n) => n.uid === node.uid)],
					li: node,
					ld: dataBefore.nodes.find((n) => n.uid === node.uid),
				})),
				{},
				shareDbCallback(
					`Updated ${nodesToUpdate.length} nodes which moved for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		delete: (nodes: NCNode[], callback: (() => void) | null = null): void => {
			if (nodes.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't delete nodes without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const nodesAndIndices = nodes.map((node) => {
				const index = dataBefore.nodes.findIndex((n) => n.uid === node.uid);
				return { node, index };
			});
			// Sort into reverse index order so that we can delete from the end of the array first
			nodesAndIndices.sort((a, b) => b.index - a.index);

			doc.submitOp(
				nodesAndIndices.map((item) => ({
					p: [...path, 'nodes', item.index],
					ld: item.node,
				})),
				{},
				shareDbCallback(
					`Deleted ${nodes.length} nodes for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
	};
};

export const makeSaveLinksFunctions = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean,
	graphMode: GraphMode
) => {
	return {
		create: (links: NCLink[], callback: (() => void) | null = null): void => {
			if (links.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't save links without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			doc.submitOp(
				minimizeLinks(links).map((link, i) => ({
					p: [...path, 'links', dataBefore.links.length + i],
					li: link,
				})),
				{},
				shareDbCallback(
					`Created ${links.length} links for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		update: (links: NCLink[], callback: (() => void) | null = null): void => {
			if (links.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't update links without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			doc.submitOp(
				minimizeLinks(links).map((link) => ({
					p: [...path, 'links', dataBefore.links.findIndex((l) => isSameLink(l, link))],
					li: link,
					ld: dataBefore.links.find((l) => isSameLink(l, link)),
				})),
				{},
				shareDbCallback(
					`Updated ${links.length} links for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		delete: (links: NCLink[], callback: (() => void) | null = null): void => {
			if (links.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't delete links without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const linksAndIndices = minimizeLinks(links).map((link) => {
				const index = dataBefore.links.findIndex((l) => isSameLink(l, link));
				return { link, index };
			});
			// Sort into reverse index order so that we can delete from the end of the array first
			linksAndIndices.sort((a, b) => b.index - a.index);

			doc.submitOp(
				linksAndIndices.map((item) => ({
					p: [...path, 'links', item.index],
					ld: item.link,
				})),
				{},
				shareDbCallback(
					`Deleted ${links.length} links for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		deleteMissing: (links: NCLinkMinimal[], callback: (() => void) | null = null): void => {
			console.log('[deleteMissing called]');

			if (links.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			console.log('[path]', path);

			if (!dataBefore) {
				throw new Error("Can't delete links without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const linksAndIndices = links
				.map((link) => {
					const index = dataBefore.links.findIndex((l) => isSameLink(l, link));
					return { link, index };
				})
				.filter((l) => l.index >= 0);
			// Sort into reverse index order so that we can delete from the end of the array first
			linksAndIndices.sort((a, b) => b.index - a.index);

			doc.submitOp(
				linksAndIndices.map((item) => ({
					p: [...path, 'links', item.index],
					ld: item.link,
				})),
				{},
				shareDbCallback(
					`Deleted ${links.length} faulty links for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
	};
};

export const makeSaveClustersFunctions = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean,
	graphMode: GraphMode
) => {
	return {
		create: (clusters: NCCluster[], callback: (() => void) | null = null): void => {
			if (clusters.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't save clusters without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const ops = [];
			if (!dataBefore.clusters) {
				ops.push({
					p: [...path, 'clusters'],
					oi: [],
				});
			}
			ops.push(
				...clusters.map((cluster, i) => ({
					p: [...path, 'clusters', (dataBefore.clusters?.length || 0) + i],
					li: cluster,
				}))
			);

			doc.submitOp(
				ops,
				{},
				shareDbCallback(
					`Created ${clusters.length} clusters for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		update: (clusters: NCCluster[], callback: (() => void) | null = null): void => {
			if (clusters.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't update clusters without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			doc.submitOp(
				clusters.map((cluster) => ({
					p: [
						...path,
						'clusters',
						dataBefore.clusters!.findIndex((c) => isSameCluster(c, cluster)),
					],
					li: cluster,
					ld: dataBefore.clusters!.find((c) => isSameCluster(c, cluster)),
				})),
				{},
				shareDbCallback(
					`Updated ${clusters.length} clusters for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		delete: (clusters: NCCluster[], callback: (() => void) | null = null): void => {
			if (clusters.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't delete clusters without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const clustersAndIndices = clusters.map((cluster) => {
				const index = dataBefore.clusters!.findIndex((c) => isSameCluster(c, cluster));
				return { cluster, index };
			});
			// Sort into reverse index order so that we can delete from the end of the array first
			clustersAndIndices.sort((a, b) => b.index - a.index);

			doc.submitOp(
				clustersAndIndices.map((item) => ({
					p: [...path, 'clusters', item.index],
					ld: item.cluster,
				})),
				{},
				shareDbCallback(
					`Deleted ${clusters.length} clusters for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
	};
};

export const makeSaveGroupsFunctions = (
	doc: sharedb.Doc<NCBoard>,
	userFieldName: UserFieldName,
	isCollaborative: boolean,
	graphMode: GraphMode
) => {
	return {
		create: (groups: NCGroup[], callback: (() => void) | null = null): void => {
			if (groups.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't save groups without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const ops = [];
			if (!dataBefore.groups) {
				ops.push({
					p: [...path, 'groups'],
					oi: [],
				});
			}
			ops.push(
				...minimizeGroups(groups).map((group, i) => ({
					p: [...path, 'groups', (dataBefore.groups?.length || 0) + i],
					li: group,
				}))
			);

			doc.submitOp(
				ops,
				{},
				shareDbCallback(
					`Created ${groups.length} groups for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		update: (groups: NCGroup[], callback: (() => void) | null = null): void => {
			if (groups.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't update groups without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const ops = [];
			const groupsBefore = dataBefore.groups || [];
			ops.push(
				...minimizeGroups(groups).map((group) => ({
					p: [...path, 'groups', groupsBefore.findIndex((g) => g.uid === group.uid)],
					li: group,
					ld: groupsBefore.find((g) => g.uid === group.uid),
				}))
			);

			doc.submitOp(
				ops,
				{},
				shareDbCallback(
					`Updated ${groups.length} groups for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
		delete: (groups: NCGroup[], callback: (() => void) | null = null): void => {
			if (groups.length === 0) {
				callback && callback();
				return;
			}

			const [path, dataBefore] = getGraphPathAndData(
				doc,
				userFieldName,
				isCollaborative,
				graphMode
			);

			if (!dataBefore) {
				throw new Error("Can't delete groups without existing graph data");
			}

			if (callback) {
				globalThis.neuroCreate.graph?.onLoadNewGraphData(callback);
			}

			const groupsAndIndices = minimizeGroups(groups).map((group) => {
				const index = dataBefore.groups!.findIndex((g) => g.uid === group.uid);
				return { group, index };
			});
			// Sort into reverse index order so that we can delete from the end of the array first
			groupsAndIndices.sort((a, b) => b.index - a.index);

			doc.submitOp(
				groupsAndIndices.map((item) => ({
					p: [...path, 'groups', item.index],
					ld: item.group,
				})),
				{},
				shareDbCallback(
					`Deleted ${groups.length} groups for (${userFieldName}, ${
						isCollaborative ? 'collaborative' : 'personal'
					})`
				)
			);
		},
	};
};

export const getGraphName = (graphMode: GraphMode): string => {
	if (graphMode === 'inspire') return 'Inspire';
	else if (graphMode === 'ideate') return 'Ideate';
	else return 'Synthesise';
};
